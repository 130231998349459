import phone from 'phone';
import { useState } from 'react';
import validator from 'validator';
import schools from '../constants/schools';

const useLeadForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [firstName, setFirstName] = useState('');
  const isFistNameValid = firstName.length > 0;

  const [lastName, setLastName] = useState('');
  const isLastNameValid = lastName.length > 0;

  const [email, setEmail] = useState('');
  const isEmailValid = validator.isEmail(email);

  const defaultLocation = Object.values(schools)[0];
  const [location, setLocation] = useState(defaultLocation);

  const [phoneNumber, setPhoneNumber] = useState('');
  const isPhoneNumberValid = phone(phoneNumber).isValid;

  const [dob, setDob] = useState('');

  return {
    isLoading,
    setIsLoading,
    isDirty,
    setIsDirty,
    firstName,
    setFirstName,
    isFistNameValid,
    lastName,
    setLastName,
    isLastNameValid,
    email,
    setEmail,
    isEmailValid,
    location,
    setLocation,
    defaultLocation,
    phoneNumber,
    setPhoneNumber,
    isPhoneNumberValid,
    dob,
    setDob,
  };
};

export default useLeadForm;
