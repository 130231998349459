import { APIClient } from '@cmdshepard/api-client';

const ATTEMPT_LIMIT = 5;

const opt = {
  contentType: APIClient.CONTENT_TYPE.JSON,
  retryOpts: {
    retryDelay: 2500,
    retryOn: (attempt, error, response) => {
      let shouldRetry = false;

      if (error) {
        shouldRetry = attempt < ATTEMPT_LIMIT;
      } else if (response) {
        shouldRetry = [429, 502, 503, 504].includes(response.status) && attempt < ATTEMPT_LIMIT;
      }

      if (shouldRetry) {
        console.log('=== R E T R Y ===');
        console.log('attempt:', attempt);
        console.log('error:', error);
        console.log('response:', response);
        console.log('=================');
      }

      return shouldRetry;
    },
  },
};

export const submitLead = data => new APIClient({
  ...opt,
  host: process.env.REACT_APP_LEAD_API_URL,
}).post('/', data);
