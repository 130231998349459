import { useCallback } from 'react';
import LeadForm from '../components/LeadForm';
import SchoolDetailsFormGroups from '../components/SchoolDetailsFormGroups';
import useLeadForm from '../hooks/useLeadForm';
import useSchoolDetailsFormGroups from '../hooks/useSchoolDetailsFormGroups';

const AfterSchoolProgramFormPage = () => {
  const {
    isLoading,
    isDirty,
    firstName,
    setFirstName,
    isFistNameValid,
    lastName,
    setLastName,
    isLastNameValid,
    email,
    setEmail,
    isEmailValid,
    location,
    setLocation,
    phoneNumber,
    setPhoneNumber,
    isPhoneNumberValid,
    dob,
    setDob,
    defaultLocation,
    setIsLoading,
    setIsDirty,
  } = useLeadForm();

  const {
    schoolName,
    setSchoolName,
    isSchoolNameValid,
    dismissalTime,
    setDismissalTime,
    grade,
    setGrade,
    pickupDaysPerWeek,
    setPickupDaysPerWeek,
  } = useSchoolDetailsFormGroups();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    const isValid = Boolean(
      isFistNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isPhoneNumberValid &&
      isSchoolNameValid
    );

    setIsDirty(true);

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    console.log(defaultLocation);

    const data = {
      firstName,
      lastName,
      email,
      location: location || defaultLocation,
      phoneNumber,
      dob,
      schoolName,
      dismissalTime,
      grade,
      pickupDaysPerWeek,
    };

    // TODO: Submit lead to API
    console.log('Submitting lead');
    console.log(data);
    await new Promise((resolve) => setTimeout(resolve, 2500));

    setIsLoading(false);
  }, [
    isLoading,
    isFistNameValid,
    isLastNameValid,
    isEmailValid,
    isPhoneNumberValid,
    isSchoolNameValid,
    setIsDirty,
    setIsLoading,
    firstName,
    lastName,
    email,
    location,
    defaultLocation,
    phoneNumber,
    dob,
    schoolName,
    dismissalTime,
    grade,
    pickupDaysPerWeek,
  ]);


  return (
    <LeadForm
      isLoading={isLoading}
      isDirty={isDirty}
      firstName={firstName}
      setFirstName={setFirstName}
      isFistNameValid={isFistNameValid}
      lastName={lastName}
      setLastName={setLastName}
      isLastNameValid={isLastNameValid}
      email={email}
      setEmail={setEmail}
      isEmailValid={isEmailValid}
      location={location}
      setLocation={setLocation}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      isPhoneNumberValid={isPhoneNumberValid}
      dob={dob}
      setDob={setDob}
      handleSubmit={handleSubmit}
    >
      <SchoolDetailsFormGroups
        isLoading={isLoading}
        isDirty={isDirty}
        schoolName={schoolName}
        setSchoolName={setSchoolName}
        isSchoolNameValid={isSchoolNameValid}
        dismissalTime={dismissalTime}
        setDismissalTime={setDismissalTime}
        grade={grade}
        setGrade={setGrade}
        pickupDaysPerWeek={pickupDaysPerWeek}
        setPickupDaysPerWeek={setPickupDaysPerWeek}
      />
    </LeadForm>
  );
};

export default AfterSchoolProgramFormPage;
